/* eslint-disable semi */
export default interface IAuthentificationState {
  connected: boolean
  jwtToken?: IJwtToken
  parsedAccessToken?: IAccessToken
  idAccountSelected?: string
}

export interface IJwtToken {
  access_token: string,
  refresh_token: string,
  id_token: string
}

export interface IAccessToken {
  aud: string,
  azp: string,
  exp: number, // date d'expiration du token
  // eslint-disable-next-line camelcase
  extension_ROLE: string,
  iat: number, // date de génération du token
  idp: string,
  iss: string,
  nbf: number,
  sub: string, // user id
  tfp: string,
  ver: string,
  preferred_username:string,
}

export interface IIdAccountSelected {
  idAccount: string
}

export const authentificationInitialState: IAuthentificationState = {
  connected: false,
};

export interface IAuthentificationSessionStorageState {
  shouldGoToPostConnection: boolean
}

export const authentificationSessionStorageInitialState: IAuthentificationSessionStorageState = {
  shouldGoToPostConnection: true,
};
